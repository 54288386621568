import axios from "axios";
import React, { useEffect } from "react";
import { navigate, Script } from "gatsby";
import { useState } from "react";
import { blockedDomains, emailRegex, HUBSPOT_PROXY_URL } from "../constants";
import { toast, Toaster } from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

const LeadFormWithAPIForm = ({
  pageName = "",
  location,
  portalId = "25381551",
  formId = "c90a5ae1-1852-43f5-9346-a57837b9f470",
  btnText = "Get Free Trial",
  hideRevenueHero,
  btnClass = "hs-button primary large !bg-[#4f33ca]",
  inputClass = "hs-input",
  inputPlaceholder = "Work Email*",
  formClass,
  rotatingIcon = "border-white",
  errorClass,
  isFullWidth,
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = async (e) => {
    if (!error) {
      e.preventDefault();
      const [cookie] = document.cookie
        .split("; ")
        .filter((x) => x.includes("hubspotutk="));
      const [, hubspotutk] = cookie?.split("=") || ["", ""];
      setError("");
      try {
        if (emailRegex.test(email)) {
          setLoading(true);

          /**
         @summary Block verify api for temporary time
          const response = await axios.get(
          `https://verifymail.io/api/${email}?key=${VERIFY_EMAIL_API_KEY}`
        );
         */

          if (
            // response?.data?.deliverable_email &&
            // !response?.data?.catch_all &&
            // response?.data?.mx &&
            // !response?.data?.disposable
            true
          ) {
            const requestPayload = {
              fields: [
                {
                  objectTypeId: "0-1",
                  name:
                    formId === "8c4bc12c-9a83-48e9-8f88-f6a8435c82ed"
                      ? "email__temporary_"
                      : "email",
                  value: email,
                },
              ],
            };
            if (hubspotutk) {
              Object.assign(requestPayload, {
                context: {
                  hutk: hubspotutk,
                  pageUri: location?.href,
                  pageName: pageName,
                },
              });
            }
            const res = await axios.post(
              HUBSPOT_PROXY_URL,
              Object.assign(requestPayload, {
                portalId,
                formId,
              })
            );
            /**
             * Append custom properties to Heap
             */
            if (window.heap) {
              window.heap.addUserProperties({
                marketing_email: email,
              });
            }

            if (!hideRevenueHero && window.hero && btnText !== "Subscribe") {
              const _res = await window.hero.submit({
                email: email,
              });
              await window.hero.dialog.open(_res);
            }

            if (hideRevenueHero) {
              setIsOpen(true);
            }

            // if (res?.data?.redirectUri) {
            //   navigate(res?.data?.redirectUri);
            // }
            if (btnText === "Subscribe") {
              navigate("/subscribe");
            }
            if (res?.data?.inlineMessage) {
              toast.success(
                <div
                  dangerouslySetInnerHTML={{ __html: res?.data?.inlineMessage }}
                />
              );
            }
          } else {
            setError("Please enter your work e-mail.");
          }
        } else {
          setError("Email must be formatted correctly.");
        }
      } catch (error) {
        if (
          error?.response?.data &&
          error?.response?.data.errors &&
          error?.response?.data.errors[0]?.message
        ) {
          const [, errorMessage] =
            error?.response?.data?.errors[0]?.message.split(". ") || ["", ""];
          if (errorMessage) {
            setError(errorMessage ?? error?.response?.data?.errors[0]?.message);
          }
        } else {
          setError("Please enter your work e-mail.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {hideRevenueHero && (
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={() => {
              setIsOpen(false);
            }}
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-full p-4 text-center">
                <div
                  className="meetings-iframe-container w-[850px] bg-white p-10 z-50 relative"
                  data-src="https://meetings-eu1.hubspot.com/eric-landau/cvpr-calendar?embed=true"
                >
                  <div className="absolute w-4 h-4 mt-4 top-1 right-7">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-black border border-transparent focus:outline-none focus-visible:ring-0"
                      onClick={() => setIsOpen(false)}
                    >
                      X
                    </button>
                  </div>
                </div>
                <Script
                  type="text/javascript"
                  src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
                ></Script>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}

      <form
        id="hsForm_c90a5ae1-1852-43f5-9346-a57837b9f470"
        className={`hs-form-private commercial-form hsForm_c90a5ae1-1852-43f5-9346-a57837b9f470 hs-form-c90a5ae1-1852-43f5-9346-a57837b9f470 hs-form-c90a5ae1-1852-43f5-9346-a57837b9f470_8b87ddee-9460-4f6d-92c0-a8785eede26d hs-form stacked ${formClass}`}
        onSubmit={handleSubmit}
      >
        <div className="hs_email hs-email hs-fieldtype-text field hs-form-field">
          <legend
            className="hs-field-desc"
            style={{ display: "none" }}
          ></legend>
          <div className="input">
            <input
              name="email"
              placeholder={inputPlaceholder}
              type="email"
              className={inputClass}
              required
              disabled={loading}
              onChange={(event) => {
                setEmail(event.target.value);
                setError("");
                const _filtered = blockedDomains.filter((x) =>
                  event.target.value.includes(x)
                );
                if (_filtered?.length) {
                  setError("Please enter your work email.");
                }
              }}
              value={email}
            />
          </div>
          {error && (
            <ul
              className={`no-list hs-error-msgs inputs-list ${errorClass}`}
              role="alert"
            >
              <li>
                <label className="hs-error-msg">{error}</label>
              </li>
            </ul>
          )}
        </div>
        <div className={`hs_submit hs-submit ${isFullWidth && "!w-full"}`}>
          <div className="hs-field-desc" style={{ display: "none" }}></div>
          <div className="actions">
            <button
              type="submit"
              disabled={loading || !!error}
              className={btnClass}
            >
              {loading ? (
                <div className="flex items-center">
                  Submitting
                  <div
                    className={`w-4 h-4 ml-2 border-2 ${rotatingIcon} border-solid rounded-full animate-spin border-t-transparent`}
                  ></div>
                </div>
              ) : (
                <>{btnText}</>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const LeadPlaceholderForm = ({
  btnText = "Get Free Trial",
  btnClass = "hs-button primary large !bg-[#4f33ca]",
  inputClass = "hs-input",
  inputPlaceholder = "Work Email*",
  formClass,
  isFullWidth,
}) => {
  const [email, setEmail] = useState("");

  return (
    <form
      className={`placeholder-form ${formClass}`}
    >
      <div className="field">
        <legend className="hs-field-desc" style={{ display: "none" }}></legend>
        <div className="input">
          <input
            name="email"
            placeholder={inputPlaceholder}
            type="email"
            className={inputClass}
            required
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            value={email}
          />
        </div>
      </div>
      <div className={`hs_submit ${isFullWidth && "!w-full"}`}>
        <div className="hs-field-desc" style={{ display: "none" }}></div>
        <div className="actions">
          <button
            type="submit"
            className={btnClass}
          >
            {btnText}
          </button>
        </div>
      </div>
    </form>
  );
};

const LeadFormWithAPI = ({
  btnText,
  formId,
  hideRevenueHero = false,
  routerId = "231",
  btnClass,
  inputClass,
  formClass,
  inputPlaceholder,
  rotatingIcon,
  errorClass,
  isFullWidth,
}) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      // eslint-disable-next-line no-undef
      window.hero = new RevenueHero({ routerId });
    };

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://app.revenuehero.io/scheduler.min.js";
    script.onload = () => {
      setScriptLoaded(true);
      handleLoad();
    };
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  if (!scriptLoaded) {
    return (
      <LeadPlaceholderForm
        btnText={btnText}
        btnClass={btnClass}
        inputClass={inputClass}
        formClass={formClass}
        inputPlaceholder={inputPlaceholder}
        isFullWidth={isFullWidth}
      />
    ); // Render placeholder while the script is loading
  }

  return (
    <LeadFormWithAPIForm
      btnText={btnText}
      formId={formId}
      hideRevenueHero={hideRevenueHero}
      btnClass={btnClass}
      inputClass={inputClass}
      formClass={formClass}
      inputPlaceholder={inputPlaceholder}
      rotatingIcon={rotatingIcon}
      errorClass={errorClass}
      isFullWidth={isFullWidth}
    />
  );
};

export default LeadFormWithAPI;
